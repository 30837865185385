var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section customize-section",
    attrs: {
      "id": "body"
    }
  }, [_c('sub-menu', {
    staticClass: "text-center",
    attrs: {
      "items": _vm.items,
      "active-sub-page": _vm.activeSubPage
    },
    on: {
      "changeSubPage": function ($event) {
        return _vm.changeSubPage($event);
      }
    }
  }), _vm.activeSubPage === 'size' ? _c('div', [_c('customize-options', {
    attrs: {
      "items": _vm.sizes,
      "current-value": _vm.user.preferences.size
    }
  })], 1) : _vm._e(), _vm.activeSubPage === 'shirt' ? _c('div', [_c('customize-options', {
    attrs: {
      "items": _vm.freeShirts,
      "current-value": _vm.user.preferences.shirt
    }
  }), _vm.editing ? _c('customize-options', {
    attrs: {
      "items": _vm.specialShirts,
      "current-value": _vm.user.preferences.shirt,
      "full-set": !_vm.userOwnsSet('shirt', _vm.specialShirtKeys)
    },
    on: {
      "unlock": function ($event) {
        _vm.unlock(`shirt.${_vm.specialShirtKeys.join(',shirt.')}`);
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }